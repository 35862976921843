<script>

function generarColorAleatorio() {
  const letras = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letras[Math.floor(Math.random() * 16)]
  }
  return color
}

export default {
  data() {
    return {
      focus: false,
      title: ''
    }
  },
  methods: {
    createColumn() {
      const title = this.title.trim()
      if (!title) return
      
      const column = {
        title,
        color: generarColorAleatorio()
      }

      this.$emit('createColumn', column)

      this.title = ''
    }
  }
}
</script>

<template>
  <div class="creator-columns" :class="{ 'focus' : focus }">
    <textarea 
    v-model="title"
    @focus="focus = true"
    @blur="focus = false"
    @keyup.enter="createColumn"
    :class="{ 'focus' : focus }"
    class="column-title" type="text" :placeholder="focus? 'Titulo de la columna' : 'Agregar columna'"></textarea>
    <transition name="fade">
      <div v-show="focus" class="controls">
        <div @click="createColumn" class="btn btn-outline-success">Crear</div>
        <div @clcik="title = '';  focus = false" class="btn btn-outline-danger">Cancelar</div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.creator-columns {
  display: inline-block;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: .5s ease-in-out;
  position: relative;
  min-width: 250px;
  border-radius: 3px;
  cursor: move;
  box-shadow: 0 0 4px 2px var( --lego-shadow);
  background-color: var(--lego-bg);
}
.creator-columns.focus{
  max-height: 150px;
  padding: .5rem;
}
.controls {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: .5rem
}

.column-title {
  background-color: transparent;
  letter-spacing: .25rem;
  border: 0;
  outline: none;
  font-weight: 600;
  cursor: pointer;
  transition: .5s ease-in-out;
  color: white;
  border-radius: 3px;
  resize: none;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 1rem;
  margin: 0;
}

.column-title.focus {
  background-color: var(--lego-light-hover);
  cursor: text;
  margin-bottom: .5rem;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>