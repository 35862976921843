<script>
export default {
  data() {
    return {
      title: '',
      focused: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.userLoggedIn
    }
  },
  methods: {
    createCard() {
      const title = this.title.trim()
      if( title === '' ) return

      const card = {
        title,
        createdBy: this.user.id,
      }

      this.$emit('createCard', card)
      this.title = ''
    },
    cancelTask( ev ) {
      this.title = ''
      this.focused = false
      ev.target.blur()
    }
  }
}
</script>
<template>
  <div class="mt-2">
    <textarea 
    class="task"
    v-model="title" 
    @keydown.tab="createCard" 
    @keyup.enter="createCard"
    @keyup.esc="cancelTask"
    @focus="focused = true"
    @blur="focused = false"
    :placeholder="!focused ? '+ Agregar tarjeta' : 'Ingrese un título para esta tarjeta...'"
    ></textarea>
    <div class="controls" :class="{ focus : focused}">
      <vs-button   class=" mr-1" @click="createCard">Crear</vs-button>
      <vs-button danger   class="" @click="cancelTask">Cancelar</vs-button>
    </div>
  </div>
</template>

<style scoped>
.task {
  width: 100%;
  border-radius: 5px;
  background-color: transparent;
  transition: .5s;
  border: none;
  outline: none;
  resize: none;
  padding: .5rem;
  color: rgb(5, 5, 5);
  height: 2rem;
}
.dark .task {
  width: 100%;
  border-radius: 5px;
  background-color: transparent;
  transition: .5s;
  border: none;
  outline: none;
  resize: none;
  padding: .5rem;
  color: white;
  height: 2rem;
}

.task:focus {
  background-color: var(--lego-light-hover);
  height: 4rem;
}

.task::-webkit-scrollbar {
  width: 0;
  visibility: hidden;
}

.controls {
  visibility: hidden;
  display: flex;
  margin-top: .5rem;
  transition: .3s;
  height: 0;
}
.controls.focus {
  visibility: visible;
  height: 40px;

}

</style>